import { gql } from "@apollo/client"

const GET_ORDER = gql`
  query GET_ORDER($id: ID) {
    order(id: $id) {
      id
      date
      status
      paymentMethod
      paymentMethodTitle
      shippingTotal
      shippingLines {
        nodes {
          shippingMethod {
            title
          }
        }
      }
      orderNumber
      total
      customer {
        firstName
        lastName
      }
      lineItems {
        nodes {
          productId
          quantity
          product {
            name
            slug
            image {
              sourceUrl
              localFile {
                publicURL
              }
            }
            ... on SimpleProduct {
              id
              name
              price
            }
          }
        }
      }
      shipping {
        address1
        address2
        city
        firstName
        lastName
        phone
        postcode
        email
      }
    }
  }
`

export default GET_ORDER
