import React from "react"
import { Link, navigate } from "gatsby"
import queryString from "query-string"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import LazyPlaceholder from "components/LazyPlaceholder/LazyPlaceholder"

import GET_ORDER from "queries/get-order"
import { isUserLoggedIn, logOut } from "utils/functions"

const Container = ({ children, userName = "-" }) => {
  const title = "Moje konto"

  const auth = isUserLoggedIn()
  const isBrowser = typeof window !== "undefined"

  const handleLogout = () => {
    logOut()
    toast.success("Poprawnie wylogowano!")
    navigate("/")
  }
  if (!auth && isBrowser) {
    navigate("/logowanie/")
    toast.warning("Użytkownik nie zalogowany.")
    return null
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="account">
        <div className="container-fluid">
          <PageHeader title={`${title} - ${userName}`} />

          <div className="row">
            <div className="col-md-3">
              <PageSubHeader title="Panel Klienta" />
              <div className="account__nav">
                <Link to="/moje-konto/">Szczegóły konta</Link>
                <Link to="/moje-konto/" className="current">
                  Zamówienia
                </Link>
                <Link to="/moje-konto/">Adresy</Link>
              </div>
              <Button onClick={handleLogout}>Wyloguj</Button>
            </div>
            <div className="col-md-8 offset-md-1">{children}</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const OrderDetails = ({ location: { search } }) => {
  const isBrowser = typeof window !== "undefined"
  const { order_id } = queryString.parse(search)
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id: order_id },
  })
  if (!order_id) {
    isBrowser && navigate("/moje-konto/")

    return null
  }
  if (loading) {
    return (
      <Container>
        <LazyPlaceholder />
      </Container>
    )
  }
  if (error) {
    return (
      <Container>
        <p>{error?.message}</p>
      </Container>
    )
  }
  const { order } = data

  return (
    <Container
      userName={`${order?.customer?.firstName} ${order?.customer?.lastName}`}
    >
      <div className="account__order">
        <PageSubHeader title={`Szczegóły zamówienia #${order?.orderNumber}`} />
        <p>
          Zamówienie numer <strong>#{order.orderNumber}</strong> złożone{" "}
          <strong>
            {order?.date?.split("T")?.[0]?.split("-").reverse().join(".")}
          </strong>{" "}
          roku zostało <strong>{order?.status}</strong>.
        </p>

        <PageSubHeader title="Produkty" />
        <div className="account__table account__table--order">
          <div className="account__table-header">
            <div className="row">
              <div className="col-md-7">
                <small>Nazwa</small>
              </div>
              <div className="col-md-2">
                <small>Liczba sztuk</small>
              </div>
              <div className="col-md-2">
                <small>Kwota (brutto)</small>
              </div>
            </div>
          </div>
          {order?.lineItems?.nodes.map(({ productId, quantity, product }) => (
            <div className="account__table-item" key={productId}>
              <div className="row align-items-center">
                <div className="col-md-1">
                  <div className="account__table-item-image">
                    <img src={product?.image?.sourceUrl} alt={product?.name} />
                  </div>
                </div>
                <div className="col-md-6">
                  <small className="account__table-mobile-label">Nazwa</small>
                  {product?.name}
                </div>
                <div className="col-md-2">
                  <small className="account__table-mobile-label">
                    Liczba sztuk
                  </small>
                  {quantity}
                </div>
                <div className="col-md-2">
                  <small className="account__table-mobile-label">
                    Kwota (brutto)
                  </small>
                  {product?.price}
                </div>
              </div>
            </div>
          ))}
        </div>

        <PageSubHeader title="Sposób dostawy" />
        <div className="row">
          <div className="col-md-7">
            {order?.shippingLines.nodes[0].shippingMethod.title}
          </div>
          <div className="col-md-2 offset-md-2">{order?.shippingTotal}</div>
        </div>

        <PageSubHeader title="Metoda płatności" />
        <div className="row">
          <div className="col-md-7">{order?.paymentMethodTitle}</div>
        </div>

        <PageSubHeader title="Podsumowanie" />

        <div className="account__table account__table--order">
          <div className="account__table-header">
            <div className="row">
              <div className="col-md-7">
                <small>Status</small>
              </div>
              <div className="col-md-2">
                <small>Liczba sztuk</small>
              </div>
              <div className="col-md-2">
                <small>Kwota (brutto)</small>
              </div>
            </div>
          </div>
          <div className="account__table-item">
            <div className="row align-items-center">
              <div className="col-md-7">
                <small className="account__table-mobile-label">Status</small>
                {order?.status}
              </div>
              <div className="col-md-2">
                <small className="account__table-mobile-label">
                  Liczba sztuk
                </small>
                {order?.lineItems?.nodes
                  .map(({ quantity }) => quantity)
                  .reduce((a, b) => a + b)}
              </div>
              <div className="col-md-2">
                <small className="account__table-mobile-label">
                  Kwota (brutto)
                </small>
                {order?.total}
              </div>
            </div>
          </div>
        </div>

        <PageSubHeader title="Dane zamawiającego" />
        <address>
          {order?.shipping?.firstName} {order?.shipping?.lastName} <br />
          {order?.shipping?.address1} {order?.shipping?.address2} <br />
          {order?.shipping?.postcode} {order?.shipping?.city} <br />
          {order?.shipping?.phone} <br />
          {order?.shipping?.email}
        </address>
        <Button type="link" to="/moje-konto/">
          Powrót
        </Button>
      </div>
    </Container>
  )
}

export default OrderDetails
